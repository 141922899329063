import User from "./user-type";
import {Employee} from "./employee-type";

interface Client {
    id: number,
    first_name: string,
    last_name: string,
    middle_name: string,
    date_of_birth: string,
    email: string,
    user_id: number,
    department_id: number,

    important_info: string,
    in_country_status_id: number,
    marital_status_id: number, 
    medical_condition: boolean,
    medical_condition_details: string,
    nationality_country_id: number,
    number_of_children: number,

    is_single: boolean,
    address_id: number,
    client_status_id: number,
    committed_crime: boolean,
    committed_crime_details: string,
    created_at: string,
    immigration_status_id: number,
    immigration_program_id: number,
    is_pdc: boolean;
    is_approved_pdc: boolean;
    assessment_type: ClientAssessmentType;
    
    power_link_id: string,
    power_link_accid: string,
    power_link_accstatus: string,
    
    flow: ClientFlow,
    immigration_package_id?: number,
    requested_immigration_package_id?: number,
    requested_immigration_package_at?: string,
    
    client_type?: ClientType;
    activity?: ClientActivity[];
    user?: User;

    stripe_id?: string,
    card_brand?: string,
    card_last_four?: string,
    trial_ends_at?: string,

    isStripeSubscriptionExist?: boolean,
    account_manager_email?: string,
    is_first_login: boolean,
    is_agreement_manager_exist?: boolean,

    account_manager?: Employee,
    rcic_manager?: Employee,
    agreement_manager?: Employee,

    subscription_status: ClientSubscriptionStatus,
    subscription_status_date: string,
    
    subs_stripe_status: string,
    subs_current_period_end: string,
    consultation_agreement_date?: string,

    is_approved_er: boolean
}

export enum ClientType{
    Lead = 'lead',
    PaidLead = 'paid_lead',
    Account = 'account'
}

export enum ClientSubscriptionStatus{
    Default,
    Subscribed,
    Expired,
    Inactive,
    ManualActive
}

export enum ClientFlow{
    Default,
    PreAssessment,
    Demo,
    PreAssessmentV2,
    flow7 = 7,
}

export interface ClientActivity {
    id: number;
    browser: string;
    country: string;
    created_at: string;
    device: string;
    ip: string;
    os: string;
    updated_at: string;
    user_id: number;
    admin_id?: number;
    name?: string;
}

export enum ClientAssessmentType{
    DEFAULT = 0,
    MANUAL = 1,
    AUTO = 2
}
export default Client;
